import * as React from "react"
import Layout from "../components/common/Layout"
import Meta from "../components/common/Meta"
import Main from "../components/common/Main"
import PageIntro from "../components/common/intro/PageIntro"

export const Head = () => <Meta title="" description="" />

const EmailRemovedPage = () => {
  const title = [["Email removed"]]
  return (
    <Layout
      notfound={true}
      style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
    >
      <Main>
        <PageIntro
          title={title}
          subheading="Your email has been successfully removed."
        />
      </Main>
    </Layout>
  )
}

export default EmailRemovedPage
